// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-casestudies-js": () => import("./../../../src/pages/casestudies.js" /* webpackChunkName: "component---src-pages-casestudies-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-project-energis-js": () => import("./../../../src/pages/project/energis.js" /* webpackChunkName: "component---src-pages-project-energis-js" */),
  "component---src-pages-project-goretex-js": () => import("./../../../src/pages/project/goretex.js" /* webpackChunkName: "component---src-pages-project-goretex-js" */),
  "component---src-pages-project-kapilendo-js": () => import("./../../../src/pages/project/kapilendo.js" /* webpackChunkName: "component---src-pages-project-kapilendo-js" */),
  "component---src-pages-project-mountlytics-js": () => import("./../../../src/pages/project/mountlytics.js" /* webpackChunkName: "component---src-pages-project-mountlytics-js" */),
  "component---src-pages-project-triphunt-js": () => import("./../../../src/pages/project/triphunt.js" /* webpackChunkName: "component---src-pages-project-triphunt-js" */)
}

